const Breakpoint = {
  mobile: 767,
  desk: 768,
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

export default Breakpoint
